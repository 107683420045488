import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import Header from './component/header/Header'
import Footer from './component/footer/Footer'
import Home from './component/Home'
import Thankyou from './component/thankyou/Thankyou'

const App = () => {
  return (
    <>
      <BrowserRouter >
        <Header />
        <Routes >
          <Route path='' element= {<Home /> } />        
          <Route path='/thankyou' element= {<Thankyou /> } />        
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App
