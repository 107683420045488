import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'

const Embedform = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [value, setValue] = useState()
    const [checkbox, setCheckBox] = useState(true)

    const [formValidation, setFormValidation] = useState({
      name: '',
      mail: '',
      mobile: '',
      terms: '',
    })
    const [formData, setFormData] = useState({
      full_name: '',
      email: '',
      mobile: '',
    })
  
    function isValidName(name) {
      if (typeof name !== 'string' || name.trim() === '') {
        return false;
      }
      const nameRegex = /^[a-zA-Z\s'-]+$/;
      return nameRegex.test(name);
    }
  
    function isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
  
    const validateForm = () => {
        let valid = true;
        const newFormValidation = { name: '', mail: '', mobile: '', terms: '' };
    
        if (!isValidName(formData.full_name)) {
          newFormValidation.name = 'Invalid name format';
          valid = false;
        }
    
        if (!isValidEmail(formData.email)) {
          newFormValidation.mail = 'Invalid email format';
          valid = false;
        }
    
        if(value.length != 0){
          if (!isValidPhoneNumber(value)) {
          newFormValidation.mobile = 'Invalid phone number format';
          valid = false;
        }
      }else{
          newFormValidation.mobile = 'Enter Phone Number';
          valid =false
        }
    
        if (!checkbox) {
          newFormValidation.terms = 'Please accept the terms and conditions';
          valid = false;
        }
    
        setFormValidation(newFormValidation);
        return valid;
      };

    const handleCheckboxChange = (e) => {
        setCheckBox(e.target.checked );
        setFormValidation({ ...formValidation, terms: '' });
    };
  
    const submitForm = (e) => {
      e.preventDefault();
  
      if (!validateForm()) {
        return;
      }
  
      setFormValidation({ ...formValidation, mail: '' });
      setLoading(true);
      console.log(value);
      
      fetch('https://statisticsassignmenthelp.com.au/form_api.php', {
        method: 'POST',
        body: JSON.stringify(formData)
      })
        .then((response) => {
          if (response.status === 200) {
            navigate('/thankyou');
          }
        })
        .catch((error) => {
          setError(true);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    useEffect(()=>{
      setFormData(prevState => {
        return { ...prevState, mobile: value };
      });
    },[value])
    
    return <div className="row">
        <div className="col-md-10 mx-auto">
            <h3 className="text-center mb-3">Get 50% of on Statistics Assignment Help</h3>
            <form action="" onSubmit={(e) => submitForm(e)}>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label text-black">Name</label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="john dee" value={formData.full_name}
                        onChange={(e) => setFormData({ ...formData, full_name: e.target.value })}
                    />
                    {
                        formValidation.name ? (<p className="text-danger">{formValidation.name}</p>) : ''
                    }
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput3" className="form-label text-black">Email address</label>
                    <input type="email" className="form-control" id="exampleFormControlInput2" placeholder="name@example.com" value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                    {
                        formValidation.mail ? (<p className="text-danger">{formValidation.mail}</p>) : ''
                    }
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput3" className="form-label text-black">Mobile Number</label>
                    <PhoneInput
                        placeholder="Enter phone number"
                        value={value}
                        className="form-control"
                        onChange={setValue}
                    />
                    {
                        formValidation.mobile ? (<p className="text-danger">{formValidation.mobile}</p>) : ''
                    }
                </div>
                <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="termsCheckbox"
              checked={checkbox}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="termsCheckbox">
              I accept the terms and conditions
            </label>
            {formValidation.terms && (
              <p className="text-danger">{formValidation.terms}</p>
            )}
          </div>
                <button type="submit" className="main-btn w-100">{loading ? 'Processing' : 'Submit'}</button>
                {
                    error ? (<p className="text-center">Something went wrong! please try again</p>) : ''
                }
            </form>
        </div>
    </div>;
}

export default Embedform
