import React from 'react'
import Head from './Head'

const Header = () => {
  return (
    <>
      <Head />
    </>
  )
}

export default Header
