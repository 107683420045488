import React, { useEffect } from 'react'
import './head.css'
import logo from '../images/logo.webp'

const Head = () => {
    const closeNav = () => {
        let a = document.getElementById('navButton')
        a.classList.add('collapsed')
        let b = document.getElementById('navbarNine')
        b.classList.remove('show')
    };
    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector('.navbar-area');
            if (window.scrollY > 50) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <header className="header-area">
                <div className="navbar-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg">
                                    <a className="navbar-brand" href="/">
                                        <img src={logo} alt="Logo" loading='lazy'/>
                                    </a>
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNine"
                                        aria-controls="navbarNine" aria-expanded="false" aria-label="Toggle navigation" id="navButton">
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse navi" id="navbarNine">
                                        <ul className="navbar-nav">
                                            <li className="nav-item">
                                                <a className="page-scroll active" href="#" onClick={closeNav}>Home</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#services" onClick={closeNav}>Services</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#review" onClick={closeNav}>Review </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#faq" onClick={closeNav}>FAQs</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#footer" onClick={closeNav}>Contact us</a>
                                            </li>

                                        </ul>
                                    </div>

                                </nav>

                            </div>
                        </div>
                    </div>
                </div>

               
            </header>
        </>
    )
}

export default Head
