import React from 'react'
import '../header/head.css'
import Embedform from '../embedForm/Embedform';


const Banner = () => {
    return (
        <>
            <div id="home" className="header-hero bg_cover pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="header-hero-content text-center">
                                <h1 className="header-title">
                                    Best Statistics Assignment Help Australia: Hire Online Statistics Assignment Experts!
                                </h1>
                                <p className="text " >Need help with statistics assignment? With our statistics assignment help services, you get an opportunity to grasp complex data and convert it into actionable insights along with making sure that you not only meet your assignment deadlines but also understand what you are going to submit!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="form">
                                <div className="banner_form bg-white">
                                    <Embedform />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Banner
