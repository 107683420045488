import React from 'react'
import './footer.css'
import logo from '../images/logo.webp'




const Footer = () => {
    return (
        <>
            <footer id="footer" className="footer-area pt-120">
                <div className="container">                   
                    <div className="footer-widget pb-60 pt-100">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-8">
                                <div className="footer-about mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <a className="logo" href="/">
                                        <img src={logo} alt="logo" />
                                    </a>
                                    <p className="text">STATISTICS ASSIGNMENT HELP AU has cultivated a legacy of academic excellence with Ph.D. experts
                                            online. We are the proud owners of a legacy and aim to provide the best statistics assignment help
                                            to students anytime and anywhere they need!</p>
                                    <ul className="social">
                                        <li><a href="/"><i className="fab fa-facebook"></i></a></li>
                                        <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                            <div className="col-lg-3 col-md-5 col-sm-5">
                                <div className="footer-contact mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                                    <div className="footer-title">
                                        <h4 className="title">Contact Us</h4>
                                    </div>
                                    <ul className="contact">
                                        <li><a  className='phone' href="tel:61488896118">+61 4888 96 118</a></li>
                                        <li><a href="mailto:help@learnwithfraternity.com" >help@statisticassignmenthelp.com.au </a></li>
                                        <li>Endeavour Hills Victoria, Australia PO Box: 3802 Australia</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright d-sm-flex justify-content-between">
                                    <div className="copyright-content">
                                        <p className="text">statisticassignmenthelp.com.au </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </footer >
        </>
    )
}

export default Footer
