import React from 'react'
import './content.css'
import aboutImg from '../images/about1.svg'

const Content = () => {
    return (
        <>
            <section className="about-area about-five">
                <div className="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div class="section-title text-center pb-40">
                                <div class="line m-auto"></div>
                                <h2 class="title">We Have Served More Than 45,000 Students With Our <span> Statistics Assignment Help in The Past 4 Years!</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="about-image-five">
                                <svg className="shape" width="106" height="134" viewBox="0 0 106 134" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="1.66654" cy="1.66679" r="1.66667" fill="#DADADA" />
                                    <circle cx="1.66654" cy="16.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="1.66654" cy="31.0001" r="1.66667" fill="#DADADA" />
                                    <circle cx="1.66654" cy="45.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="1.66654" cy="60.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="1.66654" cy="88.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="1.66654" cy="117.667" r="1.66667" fill="#DADADA" />
                                    <circle cx="1.66654" cy="74.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="1.66654" cy="103" r="1.66667" fill="#DADADA" />
                                    <circle cx="1.66654" cy="132" r="1.66667" fill="#DADADA" />
                                    <circle cx="16.3333" cy="1.66679" r="1.66667" fill="#DADADA" />
                                    <circle cx="16.3333" cy="16.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="16.3333" cy="31.0001" r="1.66667" fill="#DADADA" />
                                    <circle cx="16.3333" cy="45.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="16.333" cy="60.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="16.333" cy="88.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="16.333" cy="117.667" r="1.66667" fill="#DADADA" />
                                    <circle cx="16.333" cy="74.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="16.333" cy="103" r="1.66667" fill="#DADADA" />
                                    <circle cx="16.333" cy="132" r="1.66667" fill="#DADADA" />
                                    <circle cx="30.9998" cy="1.66679" r="1.66667" fill="#DADADA" />
                                    <circle cx="74.6665" cy="1.66679" r="1.66667" fill="#DADADA" />
                                    <circle cx="30.9998" cy="16.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="74.6665" cy="16.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="30.9998" cy="31.0001" r="1.66667" fill="#DADADA" />
                                    <circle cx="74.6665" cy="31.0001" r="1.66667" fill="#DADADA" />
                                    <circle cx="30.9998" cy="45.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="74.6665" cy="45.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="31" cy="60.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="74.6668" cy="60.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="31" cy="88.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="74.6668" cy="88.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="31" cy="117.667" r="1.66667" fill="#DADADA" />
                                    <circle cx="74.6668" cy="117.667" r="1.66667" fill="#DADADA" />
                                    <circle cx="31" cy="74.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="74.6668" cy="74.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="31" cy="103" r="1.66667" fill="#DADADA" />
                                    <circle cx="74.6668" cy="103" r="1.66667" fill="#DADADA" />
                                    <circle cx="31" cy="132" r="1.66667" fill="#DADADA" />
                                    <circle cx="74.6668" cy="132" r="1.66667" fill="#DADADA" />
                                    <circle cx="45.6665" cy="1.66679" r="1.66667" fill="#DADADA" />
                                    <circle cx="89.3333" cy="1.66679" r="1.66667" fill="#DADADA" />
                                    <circle cx="45.6665" cy="16.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="89.3333" cy="16.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="45.6665" cy="31.0001" r="1.66667" fill="#DADADA" />
                                    <circle cx="89.3333" cy="31.0001" r="1.66667" fill="#DADADA" />
                                    <circle cx="45.6665" cy="45.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="89.3333" cy="45.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="45.6665" cy="60.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="89.3333" cy="60.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="45.6665" cy="88.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="89.3333" cy="88.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="45.6665" cy="117.667" r="1.66667" fill="#DADADA" />
                                    <circle cx="89.3333" cy="117.667" r="1.66667" fill="#DADADA" />
                                    <circle cx="45.6665" cy="74.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="89.3333" cy="74.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="45.6665" cy="103" r="1.66667" fill="#DADADA" />
                                    <circle cx="89.3333" cy="103" r="1.66667" fill="#DADADA" />
                                    <circle cx="45.6665" cy="132" r="1.66667" fill="#DADADA" />
                                    <circle cx="89.3333" cy="132" r="1.66667" fill="#DADADA" />
                                    <circle cx="60.3333" cy="1.66679" r="1.66667" fill="#DADADA" />
                                    <circle cx="104" cy="1.66679" r="1.66667" fill="#DADADA" />
                                    <circle cx="60.3333" cy="16.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="104" cy="16.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="60.3333" cy="31.0001" r="1.66667" fill="#DADADA" />
                                    <circle cx="104" cy="31.0001" r="1.66667" fill="#DADADA" />
                                    <circle cx="60.3333" cy="45.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="104" cy="45.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="60.333" cy="60.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="104" cy="60.3335" r="1.66667" fill="#DADADA" />
                                    <circle cx="60.333" cy="88.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="104" cy="88.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="60.333" cy="117.667" r="1.66667" fill="#DADADA" />
                                    <circle cx="104" cy="117.667" r="1.66667" fill="#DADADA" />
                                    <circle cx="60.333" cy="74.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="104" cy="74.6668" r="1.66667" fill="#DADADA" />
                                    <circle cx="60.333" cy="103" r="1.66667" fill="#DADADA" />
                                    <circle cx="104" cy="103" r="1.66667" fill="#DADADA" />
                                    <circle cx="60.333" cy="132" r="1.66667" fill="#DADADA" />
                                    <circle cx="104" cy="132" r="1.66667" fill="#DADADA" />
                                </svg>
                                <img src={aboutImg} alt="about" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="about-five-content">
                                <h6 className="small-title text-lg">OUR STORY</h6>
                                <h2 className="main-title fw-bold">Get to Know Our Statistics Assignment Helper Service before Making an Order</h2>
                                <div className="about-five-tab">
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <h3 className="nav-link active" id="nav-who-tab" data-bs-toggle="tab" data-bs-target="#nav-who"
                                                type="button" role="tab" aria-controls="nav-who" aria-selected="true">About Us</h3>
                                            <h3 className="nav-link" id="nav-vision-tab" data-bs-toggle="tab" data-bs-target="#nav-vision"
                                                type="button" role="tab" aria-controls="nav-vision" aria-selected="false">our Vision</h3>
                                            <h3 className="nav-link" id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history"
                                                type="button" role="tab" aria-controls="nav-history" aria-selected="false">our History</h3>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-who" role="tabpanel" aria-labelledby="nav-who-tab">
                                            <p>Being the leading statistics assignment helper online, we ensure to offer everything under one roof. For decades, our statistics assignment writing services have worked with professionals from Ph.D. who have assisted thousands of students in achieving success in their educational paths. We are recognized for providing quality statistics assignment help service at notable levels of reliability focusing on timely delivery. Understanding that statistics can be one of the major subjects in the curriculum of a student’s study syllabus, we never fail when it comes well research and perfectly structured tasks as per the requirements raised by the students. </p>
                                        </div>
                                        <div className="tab-pane fade" id="nav-vision" role="tabpanel" aria-labelledby="nav-vision-tab">
                                            <p>Our online statistics assignment help with a vision to equip students with a better understanding of statistics so that they are able to find their way around complicated issues that involve data analysis and research. Our objective is to be a source of illumination throughout their study by offering excellent help with statistics assignment and providing them with a chance to score high without performing the hard work. Our vision is to make each and every student struggling with their assignments feel confident while making submissions and become victorious in the fight of dealing with constant challenges with the assignments their whole life.</p>
                                        </div>
                                        <div className="tab-pane fade" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">
                                            <p>We provide unparalleled custom statistics assignment help services with a focus on student’s individual requirements. We have the best statistics assignment experts who spend their time trying to better equip the students with statistical knowledge in order to improve their ability to solve any problems using analytical techniques. We aim to turn this nightmare of statistics into a pleasure leading one step further in their educational development. It is our mission to provide students with excellent statistics assignment help in every corner of Australia and never let a student feel alone with their challenging assignment problems.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Content
