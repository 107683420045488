import React from 'react'
import './trusted.css'
import google from '../images/google.webp'
import sitejabber from '../images/sitejabber.webp'
import trust from '../images/trust.webp'

const Trusted = () => {
    return (
        <>
            <section className="truedby section features pb-120">
                <div className="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="section-title text-center pb-40">
                                <div class="line m-auto"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-lg-4 col-md-6">
                            <div className="trusted-block">
                                <div className="trusted-block-inner">
                                    <div className="rating">4.6</div>
                                    <div className="star">
                                        <div className="rate_star">
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.5 9.75h-8.063L12 2.25l-2.438 7.5H1.5l6.563 4.5-2.532 7.5L12 17.062l6.469 4.688-2.532-7.5 6.563-4.5Z"></path>
                                                <path fill="currentColor" stroke="none" d="M12 2.25v14.813L5.531 21.75l2.532-7.5L1.5 9.75h8.063L12 2.25Z"></path>
                                            </svg>
                                            </span>

                                        </div>
                                    </div>
                                    <div className="review-count">1865 review</div>

                                    <div className="trusted-logo">
                                        <img className='googleImg' src={google} alt="logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="trusted-block">
                                <div className="trusted-block-inner">
                                    <div className="rating">4.3</div>
                                    <div className="star">
                                        <div className="rate_star">
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z"></path>
                                                <path fill="currentColor" stroke="none" d="M12 2.25v14.813L5.531 21.75l2.532-7.5L1.5 9.75h8.063L12 2.25Z"></path>
                                            </svg>
                                            </span>

                                        </div>
                                    </div>
                                    <div className="review-count">1267 review</div>

                                    <div className="trusted-logo">
                                        <img  className='sitejabber' src={sitejabber} alt="logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="trusted-block">
                                <div className="trusted-block-inner">
                                    <div className="rating">4.5</div>
                                    <div className="star">
                                        <div className="rate_star">
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.25 9.53h-8.608L12 1.5 9.358 9.53H.75l7.041 4.94L5.05 22.5 12 17.524l6.951 4.976-2.747-8.03 7.046-4.94Z">
                                                </path>
                                            </svg>
                                            </span>
                                            <span><svg width="25" height="25" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.5 9.75h-8.063L12 2.25l-2.438 7.5H1.5l6.563 4.5-2.532 7.5L12 17.062l6.469 4.688-2.532-7.5 6.563-4.5Z"></path>
                                                <path fill="currentColor" stroke="none" d="M12 2.25v14.813L5.531 21.75l2.532-7.5L1.5 9.75h8.063L12 2.25Z"></path>
                                            </svg>
                                            </span>

                                        </div>
                                    </div>
                                    <div className="review-count">1478 review</div>

                                    <div className="trusted-logo">
                                        <img src={trust} alt="logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
        </>
    )
}

export default Trusted
