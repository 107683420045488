import React from 'react'
import Banner from './banner/Banner'
import Trusted from './trusted/Trusted'
import Step from './steps/Step'
import Services from './services/Services'
import Subjects from './subjects/Subjects'
import Content from './content/Content'
import Cta from './cta/Cta'
import Review from './review/Review'
import Faq from './faq/Faq'

const Home = () => {
  return (
    <>
      <Banner />
      <Trusted />
      <Step />
      <Services />
      <Subjects />
      <Content />
      <Cta />
      <Review />
      <Faq />
    </>
  )
}

export default Home
