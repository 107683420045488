import React from 'react'
import './thankyou.css'
import thankYou from '../images/thankyou.webp'

const Thankyou = () => {
    return (
        <>
            <section className="thank">
                <div className="thankyou_img">
                    <img src={thankYou} alt="thanyou" loading='lazy' />
                </div>

                <div className="text-center mb-4">
                    <a href="https://statisticsassignmenthelp.com.au/" className="main-btn ">GO TO HOMEPAGE</a>
                </div>
            </section>
        </>
    )
}

export default Thankyou
