import React from 'react'
import shape from '../images/services-shape.svg'
import './step.css'

const Step = () => {
    return (
        <>
            <section id="features" className="services-area bg-d pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title text-center pb-40">
                                <div className="line m-auto"></div>
                                <h3 className="title">Freshly Brewed Statistics Assignment Help In 3 Easy Steps</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="steps_container">
                            <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s"
                                data-wow-delay="0.2s">
                                <div className="services-icon">
                                    <img className="shape" src={shape} alt="shape" />
                                    <div className="step_no">01</div>
                                </div>
                                <div className="services-content mt-30">
                                    <h3 className="services-title">Give us your details</h3>
                                    <p className="text">Our statistics assignment writers churn fresh content with the details you provide us via filling out a
                                        standardized Google Form. Filling out a standardized form helps our statistics assignment
                                        services to note down all the details that are not to be forgotten.</p>
                                </div>
                            </div>
                            <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s"
                                data-wow-delay="0.5s">
                                <div className="services-icon">
                                    <img className="shape" src={shape} alt="shape" />
                                    <div className="step_no">02</div>
                                </div>
                                <div className="services-content mt-30">
                                    <h3 className="services-title">Talk to our experts</h3>
                                    <p className="text">The next step involves a detailed discussion with the statistics assignment expert who will be
                                        handling your order. Having a discussion will clear doubts from both sides and help statistics
                                        assignment writers architect their assignments as per your needs.</p>
                                </div>
                            </div>

                            <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s"
                                data-wow-delay="0.8s">
                                <div className="services-icon">
                                    <img className="shape" src={shape} alt="shape" />
                                    <div className="step_no">03</div>
                                </div>
                                <div className="services-content mt-30">
                                    <h3 className="services-title">Make the payment</h3>
                                    <p className="text">Once you have fulfilled the requirements that help us create a customized assignment for you, the
                                        next step is to make the payment (in full or in half). As soon as you make the payment our statistics
                                        experts will begin their process of curating a custom statistics assignment for you and ensure
                                        delivery within the deadline!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Step
